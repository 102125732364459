@import "normalize.css";
body {
  font-size: 28px;
}
img {
  -ms-interpolation-mode: bicubic;
  content: normal !important;
}
ol,
ul {
  list-style: none;
  margin: 0;
}
a {
  text-decoration: none;
}
li {
  list-style-type: none;
}
label {
  margin-right: 10px;
}
img {
  width: 100%;
}
p {
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5 {
  margin-bottom: 0;
}
iframe {
  border: none;
  margin: 0;
  padding: 0;
}
.textCenter {
  text-align: center;
}
.textLeft {
  text-align: left;
}
.textRight {
  text-align: right;
}
.flexColumn {
  display: flex;
  flex-direction: column;
}
.flexRow {
  display: flex;
}
.flexRowReverse {
  flex-direction: row-reverse;
}
.flexBetween {
  justify-content: space-between;
}
.flexAround {
  justify-content: space-around;
}
.flexStart {
  justify-items: flex-start;
}
.flexEnd {
  justify-content: flex-end;
}
.flexEvenly {
  justify-content: space-evenly;
}
.flexWrap {
  flex-wrap: wrap;
}
.flexBottom {
  align-items: flex-end;
}
.flexNoShrink {
  flex-shrink: 0;
}
.vAlignMiddle {
  vertical-align: middle;
}
.center {
  justify-content: center;
  align-items: center;
}
.justCenter {
  justify-content: center;
}
.alignCenter {
  align-items: center;
}
.flex1 {
  flex: 1;
}
.pointer {
  cursor: pointer;
}
.posr {
  position: relative;
}
.bold {
  font-weight: bold;
}
.hide {
  display: none;
}
.mt10 {
  margin-top: 10px;
}
.mt30 {
  margin-top: 30px;
}
.mt40 {
  margin-top: 40px;
}
.mt20 {
  margin-top: 20px;
}
.mt50 {
  margin-top: 50px;
}
.mb10 {
  margin-bottom: 10px;
}
.w100 {
  width: 100%;
}
.w50 {
  width: 50%;
}
.ml10 {
  margin-left: 10px;
}
.ml20 {
  margin-left: 20px;
}
.mr20 {
  margin-right: 20px;
}
.ml30 {
  margin-left: 30px;
}
.ml40 {
  margin-left: 40px;
}
.ml5 {
  margin-left: 5px;
}
.mr5 {
  margin-right: 5px;
}
.mr10 {
  margin-right: 10px;
}
.mt5 {
  margin-top: 5px;
}
.mb5 {
  margin-bottom: 5px;
}
.pl30 {
  padding-left: 30px;
}
.fs14 {
  font-size: 14px;
}
.h100 {
  height: 100%;
}
.app {
  min-height: 100vh;
}
.oneLine {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.defaultColor {
  color: #333;
}
.themeColor {
  color: #2C6DD9;
}
.errorColor {
  color: #C31B00;
}
.text2em {
  text-indent: 2em;
}
.warningColor {
  color: #FF7F3A;
}
.describe {
  color: #333;
  line-height: 42px;
}
.col999 {
  color: #999;
}
.col000 {
  color: #000;
}
.colfff {
  color: #fff;
}
.col111 {
  color: #111;
}
.col333 {
  color: #333;
}
.col666 {
  color: #666;
}
.fs12 {
  font-size: 12px;
}
.fs16 {
  font-size: 16px;
}
.fs18 {
  font-size: 18px;
}
.fs28 {
  font-size: 28px;
}
.fs32 {
  font-size: 32px;
}
.fs30 {
  font-size: 30px;
}
.fs24 {
  font-size: 24px;
}
.pages {
  padding: 40px 30px;
}
.pagesTitle {
  height: 38px;
  line-height: 38px;
  padding-left: 20px;
  color: #333;
  font-size: 32px;
  font-weight: bold;
  border-left: 8px #6597FF solid;
}
.normalTitle {
  color: #111;
  font-weight: bold;
  font-size: 36px;
}
.introduce {
  font-size: 32px;
  color: #333333;
  margin: 24px 30px;
  text-indent: 2em;
  line-height: 56px;
}
.pages .introduce {
  margin: 24px 0;
}
.pagesArea {
  margin: 40px 0;
}
.iconWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: #2C6DD9;
}
.iconWrap img {
  width: 60px;
  height: 60px;
}
